.discount-divisor,
.award-divisor,
.adhered-business-divisor {
  border-bottom: 1px solid #eaeaea;
}

.main-header {
  border-bottom: 3px solid #808080b8;
}

.badge-category,
.badge-discount {
  float: right;
  margin-top: 0.3rem;
}

.vineta {
  /* background: #ffbf0f; */
  height: 25px;
  width: 5px;
  margin-right: 9px;
}

.header-part-right a {
  font-weight: 600;
}

.item-title-link,
.item-title-link:hover {
  color: #333;
}

.text-points {
  color: #00a650;
}

.benefit-list-container .list-item {
  max-width: 300px;
  height: 319px;
}

.benefit-list-container .list-item .list-thumb {
  height: 219px;
}

.benefit-list-container .list-item .benefit-description {
  height: 100px;
}

.background-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

/* .benefit-list .benefit-list-container .list-item .list-thumb {
  height: 150px;
}

.benefit-list .benefit-list-container .list-item {
  height: 250px;
}

.benefit-list .benefit-list-container .list-item .benefit-description {
  height: 85px;
} */

.menu .float-left [class^='iconsminds-']:before,
.menu .float-left [class*=' iconsminds-']:before {
  margin-left: 0;
  margin-right: 0;
}

.btn-facebook {
  background-color: #1877f2;
  border: 1px solid #1877f2;
}

.ql-align-center {
  text-align: center;
}
